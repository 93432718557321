import React from "react"
import Layout from "../components/layout"
import AgenciesMarquee from "../components/agencies-marquee"
import ProductHero from "../components/product-hero"
import CoreValues from "../components/core-values"
import Img from "gatsby-image"
import SEO from "../components/seo"
import AwardsSection from "../components/awards-section"
import LeadershipSection from "../components/leadership-section"
import { Link } from "gatsby"

const AboutUsPage = props => (
  <Layout>
    <SEO
      title="About inPowered - Silicon Valley's leading advertising AI startup"
      description="Learn more about a startup on a mission to help brands maximize their Advertising ROI."
    />
    <ProductHero
      title={[
        "Our Mission is to Help Agencies and Brands ",
        <strong>Maximize Advertising ROI</strong>,
      ]}
    />
    <div className="page-hero-image">
      <div className="container">
        <Img fluid={props.data.heroImage.childImageSharp.fluid} />
      </div>
    </div>
    <div className="container px-4">
      <h3 className="title has-text-weight-medium">Our Story</h3>
      <div className="static-page-container">
        <div>
          <Img fluid={props.data.firstImage.childImageSharp.fluid} />
        </div>
        <div>
          <p>
            In late 2014, after their first company Netshelter was acquired by
            Ziff Davis, co-founders Peyman & Pirouz had experienced firsthand
            from their journey that intrusive ads that followed consumers across
            devices, while being effective for marketers, created a negative
            experience for the consumer. The industry started catching up when
            Google first announced they would sunset third party cookies in 2020
            and Apple’s app tracking transparency was introduced in 2021.
          </p>
          <p>
            Since then inPowered AI has grown to become an industry leading
            advertising AI platform. The company’s AI solutions integrate
            directly into DSPs and Native Advertising channels to increase
            campaign performance by 50% - 300%. Whether the goal is to drive
            traffic, engagement, leads, conversions, or ROAS, inPowered AI
            deploys the right algorithms to maximize media efficiency.
          </p>
          <p>
            In 2020, the company made the Inc5000 list of America’s
            fastest-growing companies for the first time, ranking 9th in the San
            Francisco/Bay area for Marketing. The company’s AI-technology has
            been recognized by the ANA (Association of National Advertisers) for
            the “Best Use of AI” in business-to-business marketing; and also
            placed as finalists in the MediaPost OMMA Awards, Digiday Tech
            Awards, and The Drum Awards.
          </p>
        </div>
      </div>
      <h3 className="title has-text-weight-medium">Meet the Founders</h3>
      <div className="static-page-container">
        <div>
          <Img fluid={props.data.secondImage.childImageSharp.fluid} />
        </div>
        <div>
          <p>
            inPowered’s industry recognition as a pioneer in AI solutions for
            advertising is a direct result of the hustle and relentless
            dedication of its team; instilled by Peyman and Pirouz as one of the
            company’s foundational building blocks that’s been critical to its
            increasing success. “When my brother and I founded inPowered almost
            7 years ago, we could only dream of having a team that’s so
            dedicated and passionate behind our mission to help brands deliver
            real business outcomes and ROI,” said Peyman Nilforoush, CEO and
            Co-Founder.
          </p>
          <p>
            “Our core company values focus around engaging our employees,
            bringing passion to each day, keeping it real and building real
            value for our customers; all while having fun,” said Pirouz
            Nilforoush, President and Co-Founder. “Teamwork can’t happen on its
            own – it needs to be catalyzed and an integral part of workplace
            culture, the people, and their processes.”
          </p>
        </div>
      </div>
      <CoreValues image={props.data.thirdImage.childImageSharp.fluid} />
      <LeadershipSection />
    </div>
    <AwardsSection />
    <div className="has-text-centered py-6 has-background-light">
      <p className="title is-4 has-text-weight-normal">
        We are a group of driven, relentless hustlers revolutionizing
        advertising
      </p>
      <Link
        to="/careers#jobs"
        className="button is-rounded px-5 is-cta-button mt-3"
      >
        See open positions
      </Link>
    </div>
    <AgenciesMarquee />
  </Layout>
)

export default AboutUsPage

export const heroImage = graphql`
  fragment heroImage on File {
    childImageSharp {
      fluid(maxWidth: 1256, maxHeight: 540, quality: 50, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const aboutUsImage = graphql`
  fragment aboutUsImage on File {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 600, quality: 50, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    firstImage: file(relativePath: { eq: "about/1.jpeg" }) {
      ...aboutUsImage
    }
    secondImage: file(relativePath: { eq: "about/2.jpeg" }) {
      ...aboutUsImage
    }
    thirdImage: file(relativePath: { eq: "about/3.png" }) {
      ...aboutUsImage
    }
    heroImage: file(relativePath: { eq: "about/hero.jpg" }) {
      ...heroImage
    }
  }
`
