import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery, Link } from "gatsby"

export default ({}) => (
  <StaticQuery
    query={graphql`
      query {
        inc: file(relativePath: { eq: "awards/2.jpg" }) {
          childImageSharp {
            fluid(
              maxHeight: 250
              maxWidth: 400
              cropFocus: CENTER
              quality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ana: file(relativePath: { eq: "awards/3.jpg" }) {
          childImageSharp {
            fluid(
              maxHeight: 250
              maxWidth: 400
              cropFocus: CENTER
              quality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        digiday: file(relativePath: { eq: "awards/1.jpg" }) {
          childImageSharp {
            fluid(
              maxHeight: 250
              maxWidth: 400
              cropFocus: CENTER
              quality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="container mb-6">
        <div>
          <h2 className="title has-text-centered has-text-weight-medium">
            Award-Winning AI
          </h2>
        </div>
        <div className=" awards-grid">
          <Link
            target="_blank"
            to="https://news.yahoo.com/inpowered-selected-ana-winner-best-181500201.html"
            className=" has-text-centered has-text-weight-medium has-text-black	"
          >
            <Img
              fluid={data.ana.childImageSharp.fluid}
              style={{ width: "100%" }}
            />
            <p>Best Use of AI/Machine Learning</p>
          </Link>
          <Link
            target="_blank"
            to="https://www.inc.com/inc5000/2020"
            className=" has-text-centered has-text-weight-medium has-text-black	"
          >
            <Img
              fluid={data.inc.childImageSharp.fluid}
              style={{ width: "100%" }}
            />
            <p>9th fastest-growing marketing company in the SF/Bay Area</p>
          </Link>
          <Link
            target="_blank"
            to="https://digiday.com/awards/vox-media-productsup-and-zephr-join-this-years-digiday-technology-awards-nominees/"
            className=" has-text-centered has-text-weight-medium has-text-black	"
          >
            <Img
              fluid={data.digiday.childImageSharp.fluid}
              style={{ width: "100%" }}
            />
            <p>Best Content Marketing Platform</p>
          </Link>
        </div>
      </div>
    )}
  />
)
