import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery, Link } from "gatsby"

export default ({ url }) => (
  <StaticQuery
    query={graphql`
      query {
        linkedinLogo: file(relativePath: { eq: "linkedin-logo.png" }) {
          childImageSharp {
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Link to={url} target="_blank" className="linkedin-link">
        <Img fixed={data.linkedinLogo.childImageSharp.fixed} />
      </Link>
    )}
  />
)
